import { compareSource, isArraysEqual } from 'HELPERS'

const checkChangesData = ({ source, cloneSource }) => {
  if (!cloneSource || (cloneSource && !Object.keys(cloneSource).length))
    return true

  const comparedSource = compareSource(source, cloneSource, ['geom'])
  const isGeomEqual = isArraysEqual(
    source?.geom?.coordinates,
    cloneSource?.geom?.coordinates
  )

  return !(source?.geom
    ? isGeomEqual && !Object.keys(comparedSource).length
    : !Object.keys(comparedSource).length)
}

const isFieldUnfilled = ({ source, field }) => {
  return (
    !(field in source) ||
    source[field] === null ||
    source[field] === undefined ||
    source[field] === '' ||
    (Array.isArray(source[field]) && !source[field].length)
  )
}

export const validateGeometry = ({ source, cloneSource, field = 'geom' }) => {
  const isNotValid = isFieldUnfilled({ source, field })

  if (isNotValid) {
    return {
      result: false,
      warnMessage: 'select_geom'
    }
  }

  const result = checkChangesData({ source, cloneSource })

  return {
    result,
    warnMessage: result ? '' : 'no_changed_data'
  }
}

const validateRequiredFields = ({ source, model, fields }) => {
  const unFilledFields = fields.reduce((acc, field) => {
    const type = model?.find(item => item.model === field)?.type
    const isUnfilled =
      type === 'json' && source[field]
        ? Object.keys(source[field]).some(x =>
            isFieldUnfilled({ source: source[field], field: x })
          )
        : isFieldUnfilled({ source, field })

    if (isUnfilled) acc.push(field)

    return acc
  }, [])

  if (unFilledFields.length) {
    return {
      result: false,
      unFilledFields,
      warnMessage: 'required_fields_not_filled'
    }
  }

  return { result: true, warnMessage: '' }
}

export const validateFields = ({
  source,
  cloneSource,
  model = [],
  fields = []
}) => {
  const requiredFields = model.reduce((acc, { required, model }) => {
    if (required) acc.push(model)

    return acc
  }, [])

  const res = validateRequiredFields({
    source,
    model,
    fields: [...fields, ...requiredFields]
  })

  if (!res.result) return res

  const validationErrors = model.reduce((acc, { model, validators }) => {
    const errors = validators?.filter(v => !v.validator(source[model], v.param))

    if (errors?.length) acc.push({ model, errors })

    return acc
  }, [])

  if (validationErrors.length) {
    return {
      result: false,
      errors: validationErrors,
      warnMessage: 'check_input'
    }
  }

  const result = checkChangesData({ source, cloneSource })

  return {
    result,
    warnMessage: result ? '' : 'no_changed_data'
  }
}
