import COLORS from 'COLORS'

const { blue, white, active, green, orange, routeColor, pink, gray } =
  COLORS.mapColors

export const additionalMapLayers = [
  { type: 'centroid', geomType: 'circle' },
  { type: 'polygon', geomType: 'fill' }
]

export const caption = {
  enabled: false,
  toggleLayerName: false,
  placeOnLine: false,
  isBg: false,
  filter: { isActive: false, value: null, operator: '==', expression: [] },
  attributeFilter: {
    isActive: false,
    value: null,
    operator: '==',
    attribute: null,
    expression: []
  },
  style: {
    type: 'symbol',
    paint: {
      'text-halo-width': 1,
      'text-halo-blur': 1,
      'text-halo-color': '#222222',
      'text-color': '#ffffff',
      'text-opacity': 1
    },
    layout: {
      'symbol-placement': 'point',
      'text-field': '',
      'text-size': 10,
      'text-offset': [0, 1],
      'text-rotate': 0,
      'text-allow-overlap': false,
      'text-pitch-alignment': 'map',
      'text-justify': 'center',
      'icon-text-fit': 'both',
      'icon-pitch-alignment': 'map',
      'icon-offset': [0, 1]
    }
  }
}

const pattern = {
  enabled: false,
  attributes: [],
  background: {
    color: blue,
    opacity: 1
  },
  style: {
    paint: {
      'line-width': 2
    }
  }
}

const icon = {
  enabled: false,
  sdf: true,
  type: 'symbol',
  style: {
    paint: {
      'icon-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        active,
        blue
      ],
      'icon-opacity': 1
    },
    layout: {
      'icon-size': 0.5,
      'icon-allow-overlap': true,
      'icon-pitch-alignment': 'map'
    }
  }
}

const point = {
  type: 'circle',
  main: {
    style: {
      paint: {
        'circle-radius': 4,
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'circle-opacity': 1,
        'circle-stroke-width': 0,
        'circle-stroke-color': white,
        'circle-stroke-opacity': 1,
        'circle-pitch-alignment': 'map'
      }
    }
  },
  filters: [],
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  heatmap: {
    enabled: false,
    opacity: 0.8,
    radius: 40,
    intensity: 40,
    currentPalette: null,
    weightField: null,
    loading: false,
    loaded: false
  },
  popup: {
    enabled: false,
    fields: []
  },
  caption,
  icon
}

const linestring = {
  type: 'line',
  main: {
    style: {
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-opacity': 1,
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'line-width': 1
      }
    }
  },
  epure: {
    type: 'fill',
    enabled: false,
    widthField: '',
    heightField: '',
    widthMultiplier: 50,
    heightMultiplier: 32,
    height: 50,
    extruded: false,
    minHeightValue: 0,
    maxHeightValue: 1,
    minWidthValue: 0,
    maxWidthValue: 1,
    labels: false,
    directions: false,
    preset: '',
    isTiled: false,
    round: null,
    style: {
      paint: {
        'fill-color': active,
        'fill-opacity': 0.5
      }
    }
  },
  filters: [],
  arrows: {
    enabled: false
  },
  popup: {
    enabled: false,
    fields: []
  },
  caption,
  icon,
  pattern
}

const polygon = {
  type: 'fill',
  cluster: {
    enabled: false,
    loading: false,
    loaded: false
  },
  main: {
    style: {
      paint: {
        'fill-opacity': 0.4,
        'fill-outline-color': blue,
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ]
      }
    }
  },
  matrix: {
    enabled: false,
    field: ''
  },
  centroid: {
    type: 'circle',
    style: {
      paint: {
        'circle-radius': 2,
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'circle-opacity': 1,
        'circle-stroke-width': 1,
        'circle-stroke-color': white,
        'circle-pitch-alignment': 'map'
      }
    }
  },
  stroke: {
    type: 'line',
    style: {
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'line-width': 2,
        'line-opacity': 1
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      }
    }
  },
  extrusion: {
    paint: {
      'fill-extrusion-height': 1500,
      'fill-extrusion-opacity': 0.8,
      'fill-extrusion-vertical-gradient': false
    },
    enabled: false,
    multiplier: 1,
    field: '',
    fieldMin: 0,
    fieldMax: 1
  },
  filters: [],
  popup: {
    enabled: false,
    fields: []
  },
  caption,
  icon
}

// for links
const arrows = {
  type: 'symbol',
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 10,
    'icon-allow-overlap': true,
    'icon-image': 'back',
    // 'icon-size': ['interpolate', ['linear'], ['zoom'], 14, 1, 22, 1],
    'icon-rotate': 180,
    'icon-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      14,
      ['literal', [0, 0]],
      22,
      ['literal', [0, -2.5]]
    ]
  },
  paint: {
    'icon-color': COLORS.root.white,
    'icon-opacity': 1
  }
}

const lanesAdd = {
  type: 'symbol',
  layout: {
    'symbol-placement': 'line-center',
    'text-field': '+',
    'text-size': 32,
    'text-offset': [0, 0.5],
    'text-rotate': 0,
    'text-allow-overlap': true,
    'text-pitch-alignment': 'map',
    'text-justify': 'center'
  },
  paint: {
    'text-halo-width': 1,
    'text-halo-blur': 1,
    'text-halo-color': '#222222',
    'text-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      active,
      blue
    ]
  }
}

const stop_area = {
  ...point,
  main: {
    style: {
      ...point.style,
      paint: {
        ...point.main.style.paint,
        'circle-radius': 2,
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          green
        ],
        'circle-opacity': 1
      }
    }
  },
  polygon: {
    style: {
      ...polygon.style,
      paint: {
        ...polygon.main.style.paint,
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          green
        ]
      }
    }
  },
  stroke: {
    type: 'line',
    style: {
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          green
        ],
        'line-width': 2,
        'line-opacity': 1
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      }
    }
  },
  icon: {
    enabled: true,
    sdf: true,
    type: 'symbol',
    style: {
      paint: {
        'icon-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          green
        ],
        'icon-opacity': 1
      },
      layout: {
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'map',
        'icon-image': 'Stops-zones'
      }
    }
  }
}

const stop_entity = {
  ...point,
  main: {
    style: {
      ...point.style,
      paint: {
        ...point.main.style.paint,
        'circle-radius': 2,
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'circle-opacity': 1
      }
    }
  },
  polygon: {
    style: {
      ...polygon.style,
      paint: {
        ...polygon.main.style.paint,
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ]
      }
    }
  },
  stroke: {
    type: 'line',
    style: {
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          active,
          blue
        ],
        'line-width': 2,
        'line-opacity': 1
      },
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      }
    }
  },
  icon: {
    enabled: true,
    sdf: true,
    type: 'symbol',
    style: {
      ...icon.style,
      layout: {
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'map',
        'icon-image': 'Stops-2'
      }
    }
  }
}

const stop_point = {
  ...point,
  icon: {
    enabled: true,
    sdf: false,
    activeIcon: 'bus-stop-selected',
    type: 'symbol',
    style: {
      ...icon.style,
      layout: {
        'icon-size': 0.5,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'viewport',
        'icon-image': 'bus-stop'
      }
    }
  }
}

const detector = {
  ...point,
  icon: {
    enabled: true,
    sdf: true,
    activeIcon: 'detector',
    type: 'symbol',
    style: {
      ...icon.style,
      layout: {
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'viewport',
        'icon-image': 'detector'
      }
    }
  }
}

const crosswalk = {
  ...point,
  icon: {
    enabled: true,
    sdf: true,
    activeIcon: 'columns',
    type: 'symbol',
    style: {
      ...icon.style,
      layout: {
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'viewport',
        'icon-image': 'columns'
      }
    }
  }
}

export const clusterLayerConfig = {
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-pitch-alignment': 'map',
    'circle-color': [
      'step',
      ['get', 'point_count'],
      green,
      50,
      orange,
      200,
      routeColor,
      1000,
      active
    ],
    'circle-opacity': 0.8,
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      12,
      50,
      14,
      200,
      16,
      1000,
      24
    ]
  }
}

export const oldGeomConfig = {
  circle: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 4,
      'circle-color': gray,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': white,
      'circle-stroke-width': 1
    }
  },
  line: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': gray
    }
  },
  fill: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': gray,
      'fill-outline-color': gray,
      'fill-opacity': 0.1
    }
  },
  stroke: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 2,
      'line-color': gray
    }
  }
}

export const createdObjectConfig = {
  point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 6,
      'circle-color': pink,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': white,
      'circle-stroke-width': 2
    }
  },
  centroid: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': pink,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': white,
      'circle-stroke-width': 1
    }
  },
  line_string: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': pink
    }
  },
  polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': pink,
      'fill-outline-color': pink,
      'fill-opacity': 0.1
    }
  },
  polygon_border: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 2,
      'line-color': pink
    }
  }
}

export const activeObjectsConfig = {
  point: {
    type: 'circle',
    layout: {},
    paint: {
      'circle-radius': 3,
      'circle-color': active,
      'circle-pitch-alignment': 'map',
      'circle-stroke-color': white,
      'circle-stroke-width': 1
    }
  },
  line_string: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 3,
      'line-color': active
    }
  },
  polygon: {
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': active,
      'fill-opacity': 0.6
    }
  },
  polygon_border: {
    type: 'line',
    layout: {},
    paint: {
      'line-width': 2,
      'line-color': active
    }
  }
}

export const directionsStyleConfig = {
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-image': 'play',
    'icon-size': 0.25
  },
  paint: {
    'icon-color': blue
  }
}
export const image = {
  layerType: 'raster'
}

export const clusterCountLayerConfig = {
  type: 'symbol',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': [
      'case',
      ['>', ['get', 'point_count'], 1000],
      [
        'concat',
        [
          'number-format',
          ['/', ['get', 'point_count'], 1000],
          { 'min-fraction-digits': 0, 'max-fraction-digits': 1 }
        ],
        ' тыс.'
      ],
      ['>', ['get', 'point_count'], 100000],
      [
        'number-format',
        ['/', ['get', 'point_count'], 1000],
        { 'max-fraction-digits': 0.1 }
      ],
      ['get', 'point_count']
    ],
    'text-size': 12,
    'text-allow-overlap': true,
    'text-pitch-alignment': 'map'
  },
  paint: {
    'text-color': white
  }
}

const count_location = {
  ...point,
  icon: {
    enabled: true,
    sdf: true,
    type: 'symbol',
    style: {
      ...icon.style,
      layout: {
        'icon-size': 0.3,
        'icon-allow-overlap': true,
        'icon-pitch-alignment': 'map',
        'icon-image': 'flag'
      }
    }
  }
}

export const styleConfigs = {
  circle: point,
  line: linestring,
  fill: polygon,
  stop_points: stop_point,
  stop_areas: stop_area,
  stops: stop_entity,
  raster: image,
  detectors: detector,
  crosswalks: crosswalk,
  arrows,
  lanesAdd,
  caption,
  icon,
  epure: linestring.epure,
  centroid: polygon.centroid,
  stroke: polygon.stroke,
  count_locations: count_location
}
